import { template as template_773f9db2c06c4a81a86fff78d3637d61 } from "@ember/template-compiler";
const SidebarSectionMessage = template_773f9db2c06c4a81a86fff78d3637d61(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
