import { template as template_70cb472376b84f09893b512f2019a390 } from "@ember/template-compiler";
const WelcomeHeader = template_70cb472376b84f09893b512f2019a390(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
