import { template as template_15275c9165c54d7c8fc06f86eb31b63f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_15275c9165c54d7c8fc06f86eb31b63f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
