import { template as template_624fed2ab38e4a4b8cc9e4dc6b3f3d8c } from "@ember/template-compiler";
const FKLabel = template_624fed2ab38e4a4b8cc9e4dc6b3f3d8c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
